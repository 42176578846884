import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Media } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import { Link } from "@ryerson/frontend.navigation";
import { Icon } from "@ryerson/frontend.assets";
import { Typography } from "@ryerson/frontend.typography";
import { useStaticQuery, graphql } from "gatsby";

export type ContentfulFile = {
	url: string;
};

export type ContentfulMedia = {
	file: ContentfulFile;
};

export type LeadershipTeamMate = {
	contentful_id: string;
	fullName: string;
	title: string;
	slug: string;
	picture: ContentfulMedia;
	node_locale: "en-US" | "fr-CA" | "es-MX";
};

export type LeadershipTeamAction = {
	actionUrl?: string;
	actionLabel?: string;
};

const Portraits = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-top: 120px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				margin-top: 45px;
			}
		`;
	}}
`;

const Portrait = styled.div`
	display: inline-block;
	width: calc(25% - 30px);
	height: auto;
	vertical-align: top;
	margin-bottom: 45px;
	img {
		width: 100%;
		height: auto;
		display: block;
		margin-bottom: 30px;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: calc(50% - 10px);
				box-sizing: border-box;
				img {
					margin-bottom: 15px;
					border: 1px solid ${theme.colors.primary.lighterGray};
				}
			}
		`;
	}}
`;

const Action = styled.div`
	display: inline-block;
	width: calc(25% - 30px);
	height: auto;
	vertical-align: top;
	margin-bottom: 45px;
	position: relative;
	padding: 40px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: calc(50% - 10px);
				padding-top: 50%;
				box-sizing: border-box;
			}
		`;
	}}
`;

const ActionIcon = styled.div`
	display: block;
	position: absolute;
	bottom: 40px;
	right: 40px;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	box-sizing: border-box;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 24px;
				height: 24px;
				right: unset;
				bottom: unset;
				top: 20px;
				left: 20px;
				line-height: 24px;
				border-radius: 24px;
			}
		`;
	}}
`;

const ContentfulTeam: React.FC<LeadershipTeamAction> = ({ actionUrl, actionLabel }) => {
	const { theme } = useTheme();

	const queryData = useStaticQuery(graphql`
		query MyQuery {
			allContentfulLeader(sort: { fields: order }) {
				edges {
					node {
						fullName
						slug
						title
						picture {
							file {
								url
							}
						}
						contentful_id
						node_locale
					}
				}
			}
		}
	`);
	const {
		localization: { language },
	} = useApplication();
	let leaders: LeadershipTeamMate[] = [];
	queryData.allContentfulLeader.edges.forEach((nodes: any) => {
		if (nodes.node.node_locale.split("-").shift() === language) {
			leaders.push(nodes.node);
		}
	});
	const [desktopHeight, setDesktopHeight] = React.useState<number>(360);
	const [mobileHeight, setMobileHeight] = React.useState<number>(360);
	React.useEffect(() => {
		if (window) {
			window.addEventListener("resize", () => {
				if (window.innerWidth >= 1160) setDesktopHeight(360);
				else {
					let imageWidth = (window.innerWidth - 200) / 4;
					let mobileImageWidth = (window.innerWidth - 60) / 2;
					let aspect = 0.7225;
					let height = imageWidth / aspect;
					let mobileHeight = mobileImageWidth / aspect;
					setMobileHeight(mobileHeight);
					setDesktopHeight(height);
				}
			});
			if (window.innerWidth >= 1160) setDesktopHeight(360);
			else {
				let imageWidth = (window.innerWidth - 200) / 4;
				let mobileImageWidth = (window.innerWidth - 60) / 2;
				let aspect = 0.7225;
				let height = imageWidth / aspect;
				let mobileHeight = mobileImageWidth / aspect;
				setMobileHeight(mobileHeight);
				setDesktopHeight(height);
			}
		}
	}, []);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<Portraits theme={theme}>
					{leaders.map((entry: LeadershipTeamMate, index: number) => {
						return (
							<Link gatsby to={"/about-us/leadership-team/" + entry.slug}>
								<Portrait
									key={index}
									theme={theme}
									css={css`
										margin-right: ${(index + 1) % 4 === 0 ? "0px" : "40px"};
									`}
								>
									<img
										src={
											entry.picture.file.url + "?fm=jpg&fit=fill&w=260&h=360"
										}
									/>
									<Typography
										css={css`
											font-size: 20px;
											margin-top: 0px;
											margin-bottom: 8px;
										`}
										variant="h4"
										color={theme.colors.primary.primaryBrand}
									>
										{entry.fullName}
									</Typography>
									<Typography
										css={css`
											white-space: pre-line;
										`}
										color={theme.colors.primary.gray}
										size="sm"
									>
										{entry.title}
									</Typography>
								</Portrait>
							</Link>
						);
					})}
					{actionLabel && actionLabel.length > 0 && actionUrl && actionUrl.length > 0 ? (
						<Link to={actionUrl}>
							<Action
								theme={theme}
								css={css`
									background-color: ${theme.colors.tertiary.background};
									height: ${desktopHeight + "px"};
								`}
							>
								<Typography color={theme.colors.primary.white} size="sm">
									{actionLabel}
								</Typography>

								<ActionIcon
									theme={theme}
									css={css`
										background-color: ${theme.colors.primary.primaryBrand};
									`}
								>
									<Icon
										icon="chevron-right"
										size="xs"
										color={theme.colors.primary.white}
										circled={false}
										css={css`
											cursor: pointer;
										`}
									/>
								</ActionIcon>
							</Action>
						</Link>
					) : (
						<></>
					)}
				</Portraits>
			</Media>
			<Media lessThan="lg">
				<Portraits theme={theme}>
					{leaders.map((entry: LeadershipTeamMate, index: number) => {
						return (
							<Link gatsby to={"/about-us/leadership-team/" + entry.slug}>
								<Portrait
									key={index}
									theme={theme}
									css={css`
										margin-right: ${(index + 1) % 2 === 0 ? "0px" : "20px"};
									`}
								>
									<img
										src={
											entry.picture.file.url + "?fm=jpg&fit=fill&w=260&h=360"
										}
									/>
									<Typography
										css={css`
											margin-top: 0px;
											margin-bottom: 0px;
										`}
										variant="h5"
										color={theme.colors.primary.primaryBrand}
									>
										{entry.fullName}
									</Typography>
									<Typography
										css={css`
											white-space: pre-line;
										`}
										type="secondary"
										size="md"
									>
										{entry.title}
									</Typography>
								</Portrait>
							</Link>
						);
					})}
					{actionLabel && actionLabel.length > 0 && actionUrl && actionUrl.length > 0 ? (
						<Action
							theme={theme}
							css={css`
								background-color: ${theme.colors.tertiary.background};
								height: ${mobileHeight + "px"};
							`}
						>
							<Typography
								css={css`
									position: absolute;
									bottom: 20px;
									left: 20px;
									padding-right: 40px;
									box-sizing: border-box;
									display: block;
									width: 100%;
								`}
								color={theme.colors.primary.white}
								weight="bold"
								size="md"
							>
								{actionLabel}
							</Typography>
							<Link to={actionUrl}>
								<ActionIcon
									theme={theme}
									css={css`
										background-color: ${theme.colors.primary.primaryBrand};
									`}
								>
									<Icon
										icon="chevron-right"
										size="xs"
										color={theme.colors.primary.white}
										circled={false}
										css={css`
											cursor: pointer;
										`}
									/>
								</ActionIcon>
							</Link>
						</Action>
					) : (
						<></>
					)}
				</Portraits>
			</Media>
		</>
	);
};

export default ContentfulTeam;
